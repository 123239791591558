.ant-form {
  width: 80%;
}

.loader{
  margin: 0 auto;
  display: 'block'; 
  padding-top: 2%;
}

.notification-detail-info{
  padding: 5px 10px;
}

.notification-detail-info.align-left{
  text-align: right;
}

.notification-detail-info.align-left > span.date{
  width: 120px;
  display: inline-block;
}

.notification-detail-info.detail > div{
  max-height: 500px;
  overflow-y: scroll;
}

.notification-detail-info.detail > div .subject{
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid gray;
}



