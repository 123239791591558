@import '~antd/dist/antd.css';
#components-form-demo-normal-login .login-form {
    max-width: 300px;
  }
  #components-form-demo-normal-login .login-form-forgot {
    float: right;
  }
  #components-form-demo-normal-login .login-form-button {
    width: 100%;                                                                                                                                                                                             
  }

  .centered-subtitle{
    text-align: center;
  }